<template>
  <div class="step-title">Projektplanungsformular</div>
  <p>Angaben zu Ihrem Projekt</p>
  <br />
  <div class="row">
    <p class="col-sm-4">Projekttitel </p><input placeholder="Projekttitel" type="text" class="test + col-sm-7"  v-model="projekttitel"> 
    <p class="col-sm-4">Projektzeitraum von</p><input type="date" class="test + col-sm-3" v-model="projektStart"> <p class="col-sm-1">bis</p>  <input type="date" class="test + col-sm-3" v-model="projektEnde">
    <p class="col-sm-4">Zielgruppe</p> 
    <select class="selects + col-sm-7" v-model="zielgruppe">
      <option value="1">Schüler*innen</option>
      <option value="2">Auszubildende</option>
      <option value="3">Ausbilder*innen</option>
    </select>
    <p class="col-sm-4">Altersstruktur</p> 
    <select class="selects + col-sm-7" v-model="alter">
      <option value="1">6 - 10 Jahre</option>
      <option value="2">11 - 15 Jahre</option>
      <option value="3">16 - 21 Jahre</option>
      <option value="4">22 - 27 Jahre</option>
      <option value="5">> 27 Jahre</option>
    </select>
  </div>
  <br />
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'


export default {
  name: 'Intro',
  props: {
    errors: {
      type: Object,
    },
  },
  data(){
    return{
      projektitelRules: yup
         .string()
        .trim()
        .required(),      
    }
  },
  computed: {
    projekttitel:{
      get(){ 
        this.$store.commit('loadLocalStorage', 'projekttitel')
        return this.$store.state.user.projekttitel      
      },
      set(value){
        this.$store.commit('updateProjekttitel', value)
      }
    },
    projektStart:{
      get(){
        return this.$store.state.user.projektStart
      },
      set(value){
        this.$store.commit('updateProjektStart', value)
      }
    },
    projektEnde:{
      get(){
        return this.$store.state.user.projektEnde
      },
      set(value){
        this.$store.commit('updateProjektEnde', value)
      }
    },
    zielgruppe:{
      get(){
        return this.$store.state.user.zielgruppe     
      },
      set(value){
        this.$store.commit('updateZielgruppe', value)
      }
    },
    alter:{
      get(){
        return this.$store.state.user.alter
      },
      set(value){
        this.$store.commit('updateAlter', value)
      }
    }


  },
   methods: {
    inputClassObject(name) {
      return {
        'input-control': true,
        'has-error': this.errors.hasOwnProperty(name),
      }
    },
  }
}
</script>

<style>

</style>
