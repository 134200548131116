<template>
  <div class="form-input">
    <label for="frist-name" class="d-flex justify-content-center">{{question}}</label>
    <div class="row">
      <p class="col-sm-4">Vorbereitungsphase</p><input type="date" class="test + col-sm-3" v-model="vorbereitungsphaseStart"> <p class="col-sm-1">bis</p>  <input type="date" class="test + col-sm-3" v-model="vorbereitungsphaseEnde">
    </div>
    <textarea
      :class="inputClassObject('vorbereitungsphase')"
      type="text"
      id="frist-name"
      name="vorbereitungsphase"
      ref="textfield"
      :rules="vorbereitungsphaseRules"
      v-model.trim="vorbereitungsphase"
      autofocus
    />
    <ErrorMessage class="input-error-msg" name="vorbereitungsphase" />
  </div>

</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
  name: 'E_Vorbereitungsphase',
  components: {
    Field,
    ErrorMessage,
  },
  mounted() {
    this.focusInput();
  },
  props: {
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      vorbereitungsphaseRules: yup
        .string()
        .trim()
        .required(),    
      question: "Bitte datieren und beschreiben Sie die einzelnen Phasen ihres Projekts",
    }
  },
  computed: {
    vorbereitungsphase: {
      get() {
        return this.$store.state.user.vorbereitungsphase
      },
      set(value) {
        this.$store.commit('updateVorbereitungsphase', value)
      },
    },
    vorbereitungsphaseStart:{
      get(){
        return this.$store.state.user.vorbereitungsphaseStart
      },
      set(value){
        this.$store.commit('updateVorbereitungsphaseStart', value)
      }
    },
    vorbereitungsphaseEnde:{
      get(){
        return this.$store.state.user.vorbereitungsphaseEnde
      },
      set(value){
        this.$store.commit('updateVorbereitungsphaseEnde', value)
      }
    },    
  },
  methods: {
    inputClassObject(name) {
      return {
        'input-control': true,
        'has-error': this.errors.hasOwnProperty(name),
      }
    },
    focusInput(){
      this.$refs.textfield.focus();
    },
  },
}
</script>

<style>

</style>