<template>
<!-- Use any element to open the sidenav -->
<div class="menu-Background">
<a @click="openNav"><div class="btn-sidebar">Menü</div></a>
</div>
  <div id="mySidenav" class="sidenav">    
  <a href="javascript:void(0)" class="closebtn" @click="closeNav">&times;</a>
    <a  v-on:click="toogleDiv('infos')" href="javascript:void(0)" ><div class="btn-menu"><h3 class="linkText">Infos abrufen</h3></div></a>

    <!-- hidden div for Infos -->
    <div id="infos">
      <a href="https://www.servicelearning.de/ueber-uns" target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">LdE in Deutschland</h3></div></a>
      <a href="https://www.servicelearning.de/lernen-durch-engagement/lde-qualitaetsstandards " target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">LdE- Qualitätsstandards</h3></div></a>
      <a href="https://www.servicelearning.de/praxis/lde-schulbeispiele-aus-dem-netzwerk " target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">LdE- Projektbeispiele</h3></div></a>
      <a href="https://www.servicelearning.de/login" target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">LdE- Netzwerkportal</h3></div></a>
      <a href="https://www.servicelearning.de/infopool" target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">LdE- Infopool</h3></div></a>
      <a href="https://www.servicelearning.de/infopool/neuigkeiten" target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">LdE- News</h3></div></a>
      <a href="https://www.servicelearning.de/newsletter" target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">LdE- Newsletter</h3></div></a>
      <a href="https://www.servicelearning.de/infopool/termine " target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">LdE- Termine</h3></div></a>
      <a href="https://www.invia-freiburg.de/themen/lernen-durch-engagement/" target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">LdE bei IN VIA</h3></div></a>
     
    </div>

    <a  v-on:click="toogleDiv('kontakt')" href="javascript:void(0)" ><div class="btn-menu"><h3 class="linkText">Kontakt aufnehmen</h3></div></a>

    <div id="kontakt">
      <a href="https://www.servicelearning.de/team " target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">LdE- Stiftungsteam</h3></div></a>
      <a href="https://www.servicelearning.de/praxis/netzwerk-lernen-durch-engagement " target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">LdE- Kompetenzzentren</h3></div></a>
      <a href="https://www.servicelearning.de/netzwerk-intern/netzwerkmitwirkende?tx_solr%5Bfilter%5D%5B0%5D=usertype%3ASchule" target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">LdE- Netzwerkschulen</h3></div></a>
      <a href="https://www.invia-freiburg.de/themen/lernen-durch-engagement/ " target="_blank" rel="noopener noreferrer" ><div class="btn-menu"><h3 class="smallerLinkText">IN VIA i. d. Erzdiözese Freiburg</h3></div></a>
      
    </div>


    <a @click="resetLocalStorage" href="javascript:void(0)" ><div class="resetBtn"><h3 class="linkText">Antrag löschen</h3></div></a>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      
    }
  },  
  methods: {
    /* Set the width of the side navigation to 250px and the left margin of the page content to 250px */
    openNav() {

      if(screen.width <= 450){
        document.getElementById("mySidenav").style.width = screen.width+"px";
        document.getElementById("main").style.marginLeft = "300px";
      }
      else{
        document.getElementById("mySidenav").style.width = "300px";
        document.getElementById("main").style.marginLeft = "300px";
      }

     
    },

    /* Set the width of the side navigation to 0 and the left margin of the page content to 0 */
    closeNav() {
      document.getElementById("mySidenav").style.width = "";
      document.getElementById("main").style.marginLeft = "";
    },

    toogleDiv(element){

      
      if(document.getElementById(element).style.display == 'block'){
        document.getElementById(element).style.display = 'none'
      }
      else{
         document.getElementById(element).style.display = 'block';
      }
    
    },

     resetLocalStorage(){
      localStorage.removeItem('projekttitel')
      localStorage.removeItem('projektStart')
      localStorage.removeItem('projektEnde')
      localStorage.removeItem('zielgruppe')
      localStorage.removeItem('alter')
      localStorage.removeItem('projektinhalte')
      localStorage.removeItem('projektthema')
      localStorage.removeItem('projektziele')
      localStorage.removeItem('projektstruktur')
      localStorage.removeItem('vorbereitungsphaseStart')
      localStorage.removeItem('vorbereitungsphaseEnde')
      localStorage.removeItem('vorbereitungsphase')
      localStorage.removeItem('recherchephaseStart')
      localStorage.removeItem('recherchephaseEnde')
      localStorage.removeItem('recherchephase')
      localStorage.removeItem('planungsphaseStart')
      localStorage.removeItem('planungsphaseEnde')
      localStorage.removeItem('planungsphase')      
      localStorage.removeItem('durchfuehrungsphaseStart')
      localStorage.removeItem('durchfuehrungsphaseEnde')
      localStorage.removeItem('durchfuehrungsphase')
      localStorage.removeItem('auswertungsphaseStart')
      localStorage.removeItem('auswertungsphaseEnde')
      localStorage.removeItem('auswertungsphase')
      localStorage.removeItem('abschlussphaseStart')
      localStorage.removeItem('abschlussphaseEnde')
      localStorage.removeItem('abschlussphase')
      localStorage.removeItem('engagement')      
      localStorage.removeItem('engagementPartner')
      localStorage.removeItem('qualitaetsstandard_1')
      localStorage.removeItem('qualitaetsstandard_2')
      localStorage.removeItem('qualitaetsstandard_3')
      localStorage.removeItem('qualitaetsstandard_4')
      localStorage.removeItem('qualitaetsstandard_5')
      localStorage.removeItem('qualitaetsstandard_6')
      localStorage.removeItem('kontaktdaten')
      localStorage.removeItem('informationen')
      localStorage.removeItem('angaben')      
      localStorage.removeItem('sinnvoll')

      //reload page after clearing storage
      location.reload()
    },
  },
}
</script>


<style scoped>
.sidenav {
 
  height: 100rem;
  padding: unset;
  padding-top: 60px;
  background-color: #082366;
  color: #ffffff;

  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}



/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}

  .row{
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
}

.btn-sidebar{ 
  margin-top: 2px;
  margin-left: 1em;
  background: #0d44ce;
  color: #ffffff;
  width: 6em;
  text-align: center; 
  font-size: 1.5em;
}

.btn-sidebar:hover{
  cursor: pointer;
  background: #1f56e2;
}

.menu-Background{
  width: 100%;
  background-color: #0a359e;
  margin: 0;
  padding: 2px;
  padding-left: 20px;
  
  
}

#infos, #kontakt{
  display: none;
  padding-left: 1em;
  margin-bottom: 1em;
}

h1 {
  font-size: 1.25rem;
}

.linkText{
 margin: 0;
 font-size: 1.5em;
}

.smallerLinkText{  
 margin: 0;
 font-size: 1.1em;
}

.resetBtn{
  margin-top: 4em; 
  background: #0d44ce;
  color: #ffffff;
  width: 100%;
  border-radius: 0;
  height: 3em; 
  text-align: center;
  padding: 0.7em;
}

.btn-menu{
  margin-top: 2px;
  background: #0d44ce;
  color: #ffffff;
  width: 100%;
  border-radius: 0;
  height: 3em; 
  text-align: center;
  padding: 0.7em;
  overflow: hidden;
}

.btn-menu:hover, .resetBtn:hover{
  background: #1f56e2;
}

#frist-name{
  display: none;
}



</style>
