<template>
  <header>
    <h1>LdE Bildungsplaner</h1>
    
  </header>
</template>

<style scoped>
header {
  padding: 5px 20px;
  padding-bottom: 2px;
  width: 100%;
  background-color: #0a359e;
  color: #ffffff;
}
h1 {
  font-size: 1.25rem;
}
</style>
