<template>
  <div class="form-input">
    <label for="frist-name" class="d-flex justify-content-center">{{question}}</label>

    <div class="row" >
      <div class="col-sm-3"></div>
      <button type="button" id="buttonRow" class="btn-primary btn-next + col-sm-3"  @click="showText">Ja</button>
      <button type="button" id="buttonRow" class="btn-primary btn-next + col-sm-3" @click="hideText">Nein</button>
    </div>
    

    <textarea
      :class="inputClassObject('engagement')"
      class="hiddenTextfield"
      type="text"
      id="frist-name"
      name="engagement"
      ref="textfield"
      :rules="engagementRules"
      v-model.trim="engagement"
      autofocus
    />
    <ErrorMessage class="input-error-msg" name="engagement" />
  </div>

</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
  name: 'K_Engagement',
  components: {
    Field,
    ErrorMessage,
  },
  mounted() {
    this.focusInput();
    this.checkText();
  },
  props: {
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      engagementRules: yup
        .string()
        .trim()
        .required(),    
      question: "Ist bereits eine Engagement-Idee vorhanden?",
    }
  },
  computed: {
    engagement: {
      get() {
        return this.$store.state.user.engagement
      },
      set(value) {
        this.$store.commit('updateEngagement', value)
      },
    },    
  },
  methods: {
    inputClassObject(name) {
      return {
        'input-control': true,
        'has-error': this.errors.hasOwnProperty(name),
      }
    },
    focusInput(){
      this.$refs.textfield.focus();
    },

     showText(){
      var textBausteine = document.getElementById("frist-name");      
        textBausteine.style.display = 'block';     
    },
    hideText(){
      var textBausteine = document.getElementById("frist-name");      
        textBausteine.style.display = 'none';     
        this.engagement = '';
    },
    checkText(){
      var textBausteine = document.getElementById("frist-name");

      if(this.engagement == ''){
        textBausteine.style.display = 'none';
      }
      else{
        textBausteine.style.display = 'block';
      }
    }
  },
}
</script>

<style>

</style>