import { createStore } from 'vuex'

const getDefaultUser = () => {
  return {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    isAgreeToTerms: false,

    //own properties
    projekttitel: '',
    projektStart: '',
    projektEnde: '',
    zielgruppe: '',
    alter: '',
    projektinhalte: '',
    projektthema: '',
    projektziele: '',
    projektstruktur: '',
    vorbereitungsphaseStart: '',
    vorbereitungsphaseEnde: '',
    vorbereitungsphase: '',
    recherchephaseStart: '',
    recherchephaseEnde: '',
    recherchephase: '',
    planungsphaseStart: '',
    planungsphaseEnde: '',
    planungsphase: '',
    durchfuehrungsphaseStart: '',
    durchfuehrungsphaseEnde: '',
    durchfuehrungsphase: '',
    auswertungsphaseStart: '',
    auswertungsphaseEnde: '',
    auswertungsphase: '',
    abschlussphaseStart: '',
    abschlussphaseEnde: '',
    abschlussphase: '',
    engagement: '',
    engagementPartner: '',
    qualitaetsstandard_1: '',
    qualitaetsstandard_2: '',
    qualitaetsstandard_3: '',
    qualitaetsstandard_4: '',
    qualitaetsstandard_5: '',
    qualitaetsstandard_6: '',

    kontaktdaten: '',
    informationen: '',
    angaben: '',
    sinnvoll:'',
  }
}

export default createStore({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    user: getDefaultUser(),
  },
  mutations: {
    resetUserState(state) {
      Object.assign(state.user, getDefaultUser())
    },
    updateFirstName(state, payload) {
      state.user.firstName = payload
    },
    updateLastName(state, payload) {
      state.user.lastName = payload
    },
    updateUsername(state, payload) {
      state.user.username = payload
    },
    updateEmail(state, payload) {
      state.user.email = payload
    },
    updateIsAgreeToTerms(state, payload) {
      state.user.isAgreeToTerms = payload
    },

    //own mutations

    updateProjekttitel(state, payload) { 
      state.user.projekttitel = payload     
      localStorage.setItem('projekttitel', state.user.projekttitel)
    },
    updateProjektStart(state, payload) {
      state.user.projektStart = payload
      localStorage.setItem('projektStart', state.user.projektStart)
    },
    updateProjektEnde(state, payload) {
      state.user.projektEnde = payload
      localStorage.setItem('projektEnde', state.user.projektEnde)
    },
    updateZielgruppe(state, payload) {
      state.user.zielgruppe = payload
      localStorage.setItem('zielgruppe', state.user.zielgruppe)
    },
    updateAlter(state, payload) {
      state.user.alter = payload
      localStorage.setItem('alter', state.user.alter)
    },
    updateProjektinhalte(state, payload) {
      state.user.projektinhalte = payload
      localStorage.setItem('projektinhalte', state.user.projektinhalte)
    },
    updateProjektthema(state, payload) {
      state.user.projektthema = payload
      localStorage.setItem('projektthema', state.user.projektthema)
    },
    updateProjektziele(state, payload) {
      state.user.projektziele = payload
      localStorage.setItem('projektziele', state.user.projektziele)
    },
    updateProjektstruktur(state, payload) {
      state.user.projektstruktur = payload
      localStorage.setItem('projektstruktur', state.user.projektstruktur)
    },
    updateVorbereitungsphaseStart(state, payload) {
      state.user.vorbereitungsphaseStart = payload
      localStorage.setItem('vorbereitungsphaseStart', state.user.vorbereitungsphaseStart)
    },
    updateVorbereitungsphaseEnde(state, payload) {
      state.user.vorbereitungsphaseEnde = payload
      localStorage.setItem('vorbereitungsphaseEnde', state.user.vorbereitungsphaseEnde)
    },
    updateVorbereitungsphase(state, payload) {
      state.user.vorbereitungsphase = payload
      localStorage.setItem('vorbereitungsphase', state.user.vorbereitungsphase)
    },
    updateRecherchephaseStart(state, payload) {
      state.user.recherchephaseStart = payload
      localStorage.setItem('recherchephaseStart', state.user.recherchephaseStart)
    },
    updateRecherchephaseEnde(state, payload) {
      state.user.recherchephaseEnde = payload
      localStorage.setItem('recherchephaseEnde', state.user.recherchephaseEnde)
    },
    updateRecherchephase(state, payload) {
      state.user.recherchephase = payload
      localStorage.setItem('recherchephase', state.user.recherchephase)
    },
    updatePlanungsphaseStart(state, payload) {
      state.user.planungsphaseStart = payload
      localStorage.setItem('planungsphaseStart', state.user.planungsphaseStart)
    },
    updatePlanungsphaseEnde(state, payload) {
      state.user.planungsphaseEnde = payload
      localStorage.setItem('planungsphaseEnde', state.user.planungsphaseEnde)
    },
    updatePlanungsphase(state, payload) {
      state.user.planungsphase = payload
      localStorage.setItem('planungsphase', state.user.planungsphase)
    },
    updateDurchfuehrungsphaseStart(state, payload) {
      state.user.durchfuehrungsphaseStart = payload
      localStorage.setItem('durchfuehrungsphaseStart', state.user.durchfuehrungsphaseStart)
    },
    updateDurchfuehrungsphaseEnde(state, payload) {
      state.user.durchfuehrungsphaseEnde = payload
      localStorage.setItem('durchfuehrungsphaseEnde', state.user.durchfuehrungsphaseEnde)
    },
    updateDurchfuehrungsphase(state, payload) {
      state.user.durchfuehrungsphase = payload
      localStorage.setItem('durchfuehrungsphase', state.user.durchfuehrungsphase)
    },
    updateAuswertungsphaseStart(state, payload) {
      state.user.auswertungsphaseStart = payload
      localStorage.setItem('auswertungsphaseStart', state.user.auswertungsphaseStart)
    },
    updateAuswertungsphaseEnde(state, payload) {
      state.user.auswertungsphaseEnde = payload
      localStorage.setItem('auswertungsphaseEnde', state.user.auswertungsphaseEnde)
    },
    updateAuswertungsphase(state, payload) {
      state.user.auswertungsphase = payload
      localStorage.setItem('auswertungsphase', state.user.auswertungsphase)
    },
    updateAbschlussphaseStart(state, payload) {
      state.user.abschlussphaseStart = payload
      localStorage.setItem('abschlussphaseStart', state.user.abschlussphaseStart)
    },
    updateAbschlussphaseEnde(state, payload) {
      state.user.abschlussphaseEnde = payload
      localStorage.setItem('abschlussphaseEnde', state.user.abschlussphaseEnde)
    },
    updateAbschlussphase(state, payload) {
      state.user.abschlussphase = payload
      localStorage.setItem('abschlussphase', state.user.abschlussphase)
    },
    updateEngagement(state, payload) {
      state.user.engagement = payload
      localStorage.setItem('engagement', state.user.engagement)
    },
    updateEngagementPartner(state, payload) {
      state.user.engagementPartner = payload
      localStorage.setItem('engagementPartner', state.user.engagementPartner)
    },
    updateQualitaetsstandard_1(state, payload) {
      state.user.qualitaetsstandard_1 = payload
      localStorage.setItem('qualitaetsstandard_1', state.user.qualitaetsstandard_1)
    },
    updateQualitaetsstandard_2(state, payload) {
      state.user.qualitaetsstandard_2 = payload
      localStorage.setItem('qualitaetsstandard_2', state.user.qualitaetsstandard_2)
    },
    updateQualitaetsstandard_3(state, payload) {
      state.user.qualitaetsstandard_3 = payload
      localStorage.setItem('qualitaetsstandard_3', state.user.qualitaetsstandard_3)
    },
    updateQualitaetsstandard_4(state, payload) {
      state.user.qualitaetsstandard_4 = payload
      localStorage.setItem('qualitaetsstandard_4', state.user.qualitaetsstandard_4)
    },
    updateQualitaetsstandard_5(state, payload) {
      state.user.qualitaetsstandard_5 = payload
      localStorage.setItem('qualitaetsstandard_5', state.user.qualitaetsstandard_5)
    },
    updateQualitaetsstandard_6(state, payload) {
      state.user.qualitaetsstandard_6 = payload
      localStorage.setItem('qualitaetsstandard_6', state.user.qualitaetsstandard_6)
    },


    updateKontaktdaten(state, payload) {
      state.user.kontaktdaten = payload
      localStorage.setItem('kontaktdaten', state.user.kontaktdaten)      
    },
    updateInformationen(state, payload) {
      state.user.informationen = payload
      localStorage.setItem('informationen', state.user.informationen)
    },
    updateAngaben(state, payload) {
      state.user.angaben = payload
      localStorage.setItem('angaben', state.user.angaben)
    },
    updateSinnvoll(state, payload) {
      state.user.sinnvoll = payload
      localStorage.setItem('sinnvoll', state.user.sinnvoll)
    },

    loadLocalStorage(state) {  
      if(localStorage.getItem('projekttitel') != 'undefined')
        state.user.projekttitel = localStorage.getItem('projekttitel')
      
      if(localStorage.getItem('projektStart') != 'undefined')
        state.user.projektStart = localStorage.getItem('projektStart')
      
      if(localStorage.getItem('projektEnde') != 'undefined')
        state.user.projektEnde = localStorage.getItem('projektEnde')
      
      if (localStorage.getItem('zielgruppe') != 'undefined')
        state.user.zielgruppe = localStorage.getItem('zielgruppe')
      
      if (localStorage.getItem('alter') != 'undefined')
        state.user.alter = localStorage.getItem('alter')
      
        if (localStorage.getItem('projektinhalte') != 'undefined')
        state.user.projektinhalte = localStorage.getItem('projektinhalte')
      
        if (localStorage.getItem('projektthema') != 'undefined')
        state.user.projektthema = localStorage.getItem('projektthema')
      
        if (localStorage.getItem('projektziele') != 'undefined')
        state.user.projektziele = localStorage.getItem('projektziele')
      
        if (localStorage.getItem('projektstruktur') != 'undefined')
        state.user.projektstruktur = localStorage.getItem('projektstruktur')
      
        if (localStorage.getItem('vorbereitungsphaseStart') != 'undefined')
        state.user.vorbereitungsphaseStart = localStorage.getItem('vorbereitungsphaseStart')
      
        if (localStorage.getItem('vorbereitungsphaseEnde') != 'undefined')
        state.user.vorbereitungsphaseEnde = localStorage.getItem('vorbereitungsphaseEnde')
      
        if (localStorage.getItem('vorbereitungsphase') != 'undefined')
        state.user.vorbereitungsphase = localStorage.getItem('vorbereitungsphase')
      
        if (localStorage.getItem('recherchephaseStart') != 'undefined')
        state.user.recherchephaseStart = localStorage.getItem('recherchephaseStart')
      
        if (localStorage.getItem('recherchephaseEnde') != 'undefined')
        state.user.recherchephaseEnde = localStorage.getItem('recherchephaseEnde')
      
        if (localStorage.getItem('recherchephase') != 'undefined')
        state.user.recherchephase = localStorage.getItem('recherchephase')
      
        if (localStorage.getItem('planungsphaseStart') != 'undefined')
        state.user.planungsphaseStart = localStorage.getItem('planungsphaseStart')
      
        if (localStorage.getItem('planungsphaseEnde') != 'undefined')
        state.user.planungsphaseEnde = localStorage.getItem('planungsphaseEnde')
      
        if (localStorage.getItem('planungsphase') != 'undefined')
        state.user.planungsphase = localStorage.getItem('planungsphase')
      
        if (localStorage.getItem('durchfuehrungsphaseStart') != 'undefined')
        state.user.durchfuehrungsphaseStart = localStorage.getItem('durchfuehrungsphaseStart')
      
        if (localStorage.getItem('durchfuehrungsphaseEnde') != 'undefined')
        state.user.durchfuehrungsphaseEnde = localStorage.getItem('durchfuehrungsphaseEnde')
      
        if (localStorage.getItem('durchfuehrungsphase') != 'undefined')
        state.user.durchfuehrungsphase = localStorage.getItem('durchfuehrungsphase')
      
        if (localStorage.getItem('auswertungsphaseStart') != 'undefined')
        state.user.auswertungsphaseStart = localStorage.getItem('auswertungsphaseStart')
      
        if (localStorage.getItem('auswertungsphaseEnde') != 'undefined')
        state.user.auswertungsphaseEnde = localStorage.getItem('auswertungsphaseEnde')
      
        if (localStorage.getItem('auswertungsphase') != 'undefined')
        state.user.auswertungsphase = localStorage.getItem('auswertungsphase')
      
        if (localStorage.getItem('abschlussphaseStart') != 'undefined')
        state.user.abschlussphaseStart = localStorage.getItem('abschlussphaseStart')
      
        if (localStorage.getItem('abschlussphaseEnde') != 'undefined')
        state.user.abschlussphaseEnde = localStorage.getItem('abschlussphaseEnde')
      
        if (localStorage.getItem('abschlussphase') != 'undefined')
        state.user.abschlussphase = localStorage.getItem('abschlussphase')
      
        if (localStorage.getItem('engagement') != 'undefined')
        state.user.engagement = localStorage.getItem('engagement')
      
        if (localStorage.getItem('engagementPartner') != 'undefined')
        state.user.engagementPartner = localStorage.getItem('engagementPartner')
      
        if (localStorage.getItem('qualitaetsstandard_1') != 'undefined')
        state.user.qualitaetsstandard_1 = localStorage.getItem('qualitaetsstandard_1')
      
        if (localStorage.getItem('qualitaetsstandard_2') != 'undefined')
        state.user.qualitaetsstandard_2 = localStorage.getItem('qualitaetsstandard_2')
      
        if (localStorage.getItem('qualitaetsstandard_3') != 'undefined')
        state.user.qualitaetsstandard_3 = localStorage.getItem('qualitaetsstandard_3')
      
        if (localStorage.getItem('qualitaetsstandard_4') != 'undefined')
        state.user.qualitaetsstandard_4 = localStorage.getItem('qualitaetsstandard_4')
      
        if (localStorage.getItem('qualitaetsstandard_5') != 'undefined')
        state.user.qualitaetsstandard_5 = localStorage.getItem('qualitaetsstandard_5')
      
        if (localStorage.getItem('qualitaetsstandard_6') != 'undefined')
        state.user.qualitaetsstandard_6 = localStorage.getItem('qualitaetsstandard_6')
      
        if (localStorage.getItem('kontaktdaten') != 'undefined')
        state.user.kontaktdaten = localStorage.getItem('kontaktdaten')
      
        if (localStorage.getItem('informationen') != 'undefined')
        state.user.informationen = localStorage.getItem('informationen')
      
        if (localStorage.getItem('angaben') != 'undefined')
        state.user.angaben = localStorage.getItem('angaben')
      
        if (localStorage.getItem('sinnvoll') != 'undefined')
        state.user.sinnvoll = localStorage.getItem('sinnvoll')
      
      
    },


  },
  actions: {
    resetUserState({ commit }) {
      commit('resetUserState')
    },
  },
  modules: {},
})
