<template>
  <div class="form-input">
    <label for="frist-name" class="d-flex justify-content-center">{{question}}</label>

    <div class="row" >
      <div class="col-sm-3"></div>
      <button type="button" id="buttonRow" class="btn-primary btn-next + col-sm-3"  @click="hideText">Ja</button>
      <button type="button" id="buttonRow" class="btn-primary btn-next + col-sm-3" @click="showText">Nein</button>
    </div>
    
    
    <label id="secondLabel" class="hiddenLabel">{{question2}}</label>
    <textarea
      :class="inputClassObject('kontaktdaten')"
      class="hiddenTextfield"
      type="text"
      id="frist-name"
      name="kontaktdaten"
      ref="textfield"
      :rules="kontaktdatenRules"
      v-model.trim="kontaktdaten"
      autofocus
    />
    <ErrorMessage class="input-error-msg" name="kontaktdaten" />
  </div>

</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
  name: 'A_kontaktdaten',
  components: {
    Field,
    ErrorMessage,
  },
  mounted() {
    this.focusInput();
    this.checkText();
  },
  props: {
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      kontaktdatenRules: yup
        .string()
        .trim()
        .required(),    
      question: "Der LdE- Bildungsplaner beinhaltet alle relevanten Kontaktdaten die ich benötige!",
      question2: "Was könnten wir aus Ihrer Sicht verbessern?",
    }
  },
  computed: {
    kontaktdaten: {
      get() {
        return this.$store.state.user.kontaktdaten
      },
      set(value) {
        this.$store.commit('updatekontaktdaten', value)
      },
    },    
  },
  methods: {
    inputClassObject(name) {
      return {
        'input-control': true,
        'has-error': this.errors.hasOwnProperty(name),
      }
    },
    focusInput(){
      this.$refs.textfield.focus();
    },

     showText(){
      var textBausteine = document.getElementById("frist-name");
      var Label = document.getElementById("secondLabel");
        textBausteine.style.display = 'block';
        Label.style.display = 'block';
    },
    hideText(){
      var textBausteine = document.getElementById("frist-name");
      var Label = document.getElementById("secondLabel");      
        textBausteine.style.display = 'none';     
        this.kontaktdaten = '';        
        Label.style.display = 'none';
    },
    checkText(){
      var textBausteine = document.getElementById("frist-name");
      var Label = document.getElementById("secondLabel");

      if(this.kontaktdaten == '' || this.kontaktdaten == null){
        textBausteine.style.display = 'none';
         Label.style.display = 'none';
      }
      else{
        textBausteine.style.display = 'block';
        Label.style.display = 'block';
      }
    }
  },
}
</script>

<style>

</style>