<template>
  <div class="form-input">
    <label for="frist-name" class="d-flex justify-content-center">{{question}}</label>
    <textarea
      :class="inputClassObject('projektstruktur')"
      type="text"
      id="frist-name"
      name="projektstruktur"
      ref="textfield"
      :rules="projektstrukturRules"
      v-model.trim="projektstruktur"
      autofocus
    />
    <ErrorMessage class="input-error-msg" name="projektstruktur" />
  </div>

</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
  name: 'D_Projektstruktur',
  components: {
    Field,
    ErrorMessage,
  },
  mounted() {
    this.focusInput();
  },
  props: {
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      projektstrukturRules: yup
        .string()
        .trim()
        .required(),    
      question: "Bitte beschreiben Sie im Folgenden wie Ihr Projektvorhaben strukturiert ist (Unterrichtsfach, Unterrichtstunden, Gruppengröße, Zeitraum etc.)",
    }
  },
  computed: {
    projektstruktur: {
      get() {
        return this.$store.state.user.projektstruktur
      },
      set(value) {
        this.$store.commit('updateProjektstruktur', value)
      },
    },    
  },
  methods: {
    inputClassObject(name) {
      return {
        'input-control': true,
        'has-error': this.errors.hasOwnProperty(name),
      }
    },
    focusInput(){
      this.$refs.textfield.focus();
    },
  },
}
</script>

<style>

</style>