<template>
  <section class="container">
    <Form v-if="!this.showUserDetail" v-slot="{ handleSubmit, values, errors }" class="row">
      <div class="step-card">
        <div class="step-body">
          <component
            :is="this.stepsList[this.currentStep]"
            :errors="errors"
          ></component>
        </div>

        <div class="step-footer">
          <div class="current-steps">
            <b>{{ this.currentStep + 1 }}</b> / {{ this.stepsList.length }}
          </div>

          <button
            @click="isLastStep ? finalSubmit() : handleSubmit($event, toNext)"
            :disabled="
              isFirstStep ? false : !validateCurrentStep(values, errors)
            "
            type="button"
            class="btn-primary btn-next"
          >
            {{ isLastStep ? `Submit` : `Next` }}
          </button>

          <button
            @click="toPrevious"
            v-show="!isFirstStep"
            type="button"
            class="btn-secondary btn-back"
          >
            Back
          </button>
        </div>
      </div>

    
    </Form>
    

    <UserDetail v-else @toRestart="toRestart" />
  </section>
</template>

<script>
import { mapActions }         from 'vuex'
import { Form }               from 'vee-validate'
import Intro                  from '../components/steps/Intro.vue'
import A_Projektthema         from '../components/steps/A_Projektthema.vue'
import B_Projektinhalte       from '../components/steps/B_Projektinhalte.vue'
import C_Projektziele         from '../components/steps/C_Projektziele.vue'
import D_Projektstruktur      from '../components/steps/D_Projektstruktur.vue'
import E_Vorbereitungsphase   from '../components/steps/E_Vorbereitungsphase.vue'
import F_Recherchephase       from '../components/steps/F_Recherchephase.vue'
import G_Planungsphase        from '../components/steps/G_Planungsphase.vue'
import H_Durchfuehrungsphase  from '../components/steps/H_Durchfuehrungsphase.vue'
import I_Auswertungsphase     from '../components/steps/I_Auswertungsphase.vue'
import J_Abschlussphase       from '../components/steps/J_Abschlussphase.vue'
import K_Engagement           from '../components/steps/K_Engagement.vue'
import L_EngagementPartner    from '../components/steps/L_EngagementPartner.vue'
import S_Qualitaetsstandard_1 from '../components/steps/S_Qualitaetsstandard_1.vue'
import T_Qualitaetsstandard_2 from '../components/steps/T_Qualitaetsstandard_2.vue'
import U_Qualitaetsstandard_3 from '../components/steps/U_Qualitaetsstandard_3.vue'
import V_Qualitaetsstandard_4 from '../components/steps/V_Qualitaetsstandard_4.vue'
import W_Qualitaetsstandard_5 from '../components/steps/W_Qualitaetsstandard_5.vue'
import X_Qualitaetsstandard_6 from '../components/steps/X_Qualitaetsstandard_6.vue'

import A_Kontaktdaten         from '../components/steps/section D/A_Kontaktdaten.vue'
import B_Informationen        from '../components/steps/section D/B_Informationen.vue'
import C_Angaben              from '../components/steps/section D/C_Angaben.vue'
import D_Sinnvoll             from '../components/steps/section D/D_Sinnvoll.vue'

export default {
  name: 'Home',
  components: {
    Form,
    Intro,    
    A_Projektthema,
    B_Projektinhalte,
    C_Projektziele,
    D_Projektstruktur,
    E_Vorbereitungsphase,
    F_Recherchephase,
    G_Planungsphase,
    H_Durchfuehrungsphase,
    I_Auswertungsphase,
    J_Abschlussphase,
    K_Engagement,
    L_EngagementPartner,
    S_Qualitaetsstandard_1,
    T_Qualitaetsstandard_2,
    U_Qualitaetsstandard_3,
    V_Qualitaetsstandard_4,
    W_Qualitaetsstandard_5,
    X_Qualitaetsstandard_6,  
    A_Kontaktdaten,
    B_Informationen,
    C_Angaben  ,
    D_Sinnvoll,
  },
  data() {
    return {
      currentStep: 0,
      stepsList: [
        'Intro',         
        'A_Projektthema', 
        'B_Projektinhalte', 
        'C_Projektziele', 
        'D_Projektstruktur',
        'E_Vorbereitungsphase',
        'F_Recherchephase',
        'G_Planungsphase',
        'H_Durchfuehrungsphase',
        'I_Auswertungsphase',
        'J_Abschlussphase',
        'K_Engagement',
        'L_EngagementPartner',
        'S_Qualitaetsstandard_1',
        'T_Qualitaetsstandard_2',
        'U_Qualitaetsstandard_3',
        'V_Qualitaetsstandard_4',
        'W_Qualitaetsstandard_5',
        'X_Qualitaetsstandard_6',
        'A_Kontaktdaten',   
        'B_Informationen',  
        'C_Angaben',
        'D_Sinnvoll',

      ],
      showUserDetail: false,
    }
  },

  computed: {
    isFirstStep() {
      return this.currentStep === 0
    },
    isLastStep() {
      return this.currentStep === this.stepsList.length - 1
    },
  },
  methods: {
    validateCurrentStep(values, errors) {
      // check if validation's errors object is empty
      const noErrors = Object.keys(errors).length === 0
      // check each of the validation's values has value
      const valuesAreNotEmpty = (obj) => {
        if (Object.keys(obj).length === 0) return true
        return Object.values(obj).every(
          (x) => !(!x || x === undefined || x.length === 0)
        )
      }

      return noErrors && valuesAreNotEmpty(values)
    },
    finalSubmit() {
      this.showUserDetail = true
    },
    toPrevious() {
      this.currentStep--
    },
    toNext() {
      this.currentStep++
    },
    toRestart() {
      this.currentStep = 0
      this.showUserDetail = false
      this.resetUserState()
    },
    ...mapActions(['resetUserState']),
  },
}
</script>

<style>
body{
  background-color: #dddddd;  
  overflow-x: hidden;
  overflow-y: auto;
}

p, h1, h2, h3, h4, h5 ,h6{
color: #dddddd;
}


.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
}

.step-card {
  padding: 20px;
  margin: 80px auto;
  vertical-align: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 600px;
  border: solid 2px #dddddd;
  background: #0a359e;
  width: 90em;
}

.step-title {
  margin: 10px 0;
  font-size: 1.75rem;
  font-weight: bold;
  color: #dddddd;
}

.step-body {
  margin-bottom: 20px;
  min-height: 350px;
}

.step-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  font-size: 1rem;
}

button {
  display: block;
  padding: 15px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

button.btn-primary {
  background-color: #dddddd;
  color: #0a359e;
}

button.btn-secondary {
  background-color: #a5a5a5;
  color: #0a359e;
}

button.btn-next {
  order: 3;
}

button.btn-back {
  order: 1;
}

.current-steps {
  padding: 10px;
  border-radius: 15px;
  background-color: #eaeaea;
  margin-right: 10px;
  margin-left: auto;
  order: 2;
}

/* From Input & Error */
.form-input {
  display: block;
  margin-bottom: 15px;
}

.form-input label {
  font-weight: 600;
  color: #dddddd;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
}

.form-input span {
  color: #ff2626;
  font-size: 0.9rem;
  display: block;
}

.form-input .input-control[type='text'],
.form-input .input-control[type='email'] {
  height: 45px;
  padding: 10px;
  width: 100%;
  background-color: #dddddd;
  border: solid 1px #dddddd;
  border-radius: 4px;
}

.form-input .input-control[type='checkbox'] {
  margin-right: 5px;
}

.form-input .input-control.has-error {
  border-color: #ff2626;
}
#buttonRow{
  margin-bottom: 1em;
  margin-right: 0.5em;
}

.ContainerBaustein{
  padding: 20px;
  position: relative;
  max-width: 600px;
  border: solid 2px #dddddd;
  background: #0d46d6;
  max-height: 478px;
  margin-top: 80px;
  scroll-behavior: auto;
  overflow-x: hidden;
  overflow-y: auto;
  
}

.textitem{
  background: #0a359e;
  border: 1px solid grey;
  margin-top: 0.3em;
  padding: 0.6em;
  color: #dddddd;
}
 

 #textBausteine{
  display: none;
}

#frist-name{
  height: 300px !important
}

#label{
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  vertical-align: bottom;
}

.textblock{
  width: 100%;
  height: auto;
  background: unset;
  border: unset;
  color: #dddddd;
  resize: unset;
  min-height: 4em;
}

.textButton{
  vertical-align: auto;
  margin-left: auto;
  margin-right: auto;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

.test{
  border: 2px gray;
  background-color: #dddddd;
  height: 15pt;
  font-size: 10pt;
}

.selects{
  height: 15pt;
  background-color: #dddddd;
}

#label{
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  vertical-align: bottom;
}

@media only screen and (max-width: 600px) {

  .test{    
    width: 91%;
    margin-left: auto;
    margin-right: auto;
  }

  .selects{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  p{    
    margin-bottom: 0;
    margin-top: 1em;
  }

  .step-card {
  width: 95%;
  margin-top: 2em;
  }

  .step-title {
    margin: 3px 0;
    font-size: 1.5rem;   
  }

.form-input label {
    font-weight: bold;  
    margin-bottom: 1em;
    font-size: 11pt;
  }

.form-input .input-control[type='text'], .form-input .input-control[type='email'] {   
   margin-top: 1em;
  }

.ContainerBaustein {
    padding: 5px;
    border: solid 1px #dddddd;    
    margin-top: 1em;   
  }

  .textitem {
    margin-top: 0.1em;
    padding: 0.3em;
  }

  h2{
    font-size: x-large;
  }

  .textblock {
      font-size: small;
  }

  .hiddenLabel{
    display: none;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

}



</style>
