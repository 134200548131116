<template>
  <div class="form-input">
    <label for="frist-name" class="d-flex justify-content-center + col-sm-12">{{question}}</label>
    <label for="frist-name" class="d-flex justify-content-center + col-sm-12">{{question2}}</label>
    <textarea
      :class="inputClassObject('qualitaetsstandard_1')"
      type="text"
      id="frist-name"
      name="qualitaetsstandard_1"
      ref="textfield"
      :rules="qualitaetsstandard_1Rules"
      v-model.trim="qualitaetsstandard_1"
      autofocus
    />
    <ErrorMessage class="input-error-msg" name="qualitaetsstandard_1" />
  </div>

  <button
   @click="showText"
    type="button"
    class="btn-primary btn-back + textButton"

  > textbausteine </button>

    <div id="textBausteine" class="ContainerBaustein + col-sm-4">
        <div class="textitem"  @click="copyText('eins')">
            <h2> Textbaustein A</h2>
            <textarea id="eins" class="textblock"  value="ein kleiner und kurzer beschreibender Text" disabled/>             
        </div>
        <div class="textitem"  @click="copyText('2')">
            <h2> Textbaustein B</h2>
            <textarea id="2" value="ein beschreibender Text der etwas länger ist als der Text von Textbaustein A. Dieser sollte länger sein" class="textblock" disabled />
        </div>
        <div class="textitem" @click="copyText('3')">
            <h2> Textbaustein C</h2>
            <textarea id="3" value="ein wirklich langer Text, der über mehrere Zeilen gehen sollte, um einen ganzen Absatz darstellen zu können. Ab hier wiederholt sich dieser Text.
            ein wirklich langer Text, der über mehrere Zeilen gehen sollte, um einen ganzen Absatz darstellen zu können. Ab hier wiederholt sich dieser Text.
            ein wirklich langer Text, der über mehrere Zeilen gehen sollte, um einen ganzen Absatz darstellen zu können. Ab hier wiederholt sich dieser Text." class="textblock" disabled/>
        </div>
      </div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { Text } from 'vue'

export default {
  name: 'S_Qualitaetsstandard_1',
  components: {
    Field,
    ErrorMessage,
  },
  mounted() {
    this.focusInput();
  },
  props: {
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      qualitaetsstandard_1Rules: yup
        .string()
        .trim()
        .required(),    
      question: 'Bitte beschreiben Sie, in welcher Art und Weise Ihre Teilnehmer*innen einen "Echter Bedarf" recherchieren (QS1)',
      question2: 'QS1: Das Engagement der Schüler*innen reagiert auf einen rechten Bedarf. Die Teilnehmer*innen übernehmen bei ihrem Engagement Aufgaben, die von allen Beteiligten als sinn- und bedeutungsvoll wahrgenommen werden.',
    }
  },
  computed: {
    qualitaetsstandard_1: {
      get() {
        return this.$store.state.user.qualitaetsstandard_1
      },
      set(value) {
        this.$store.commit('updateQualitaetsstandard_1', value)
      },
    },    
  },
  methods: {
    inputClassObject(name) {
      return {
        'input-control': true,
        'has-error': this.errors.hasOwnProperty(name),
      }
    },

    saveText(text){

      this.qualitaetsstandard_1 = text;
    },

    showText(){
      var textBausteine = document.getElementById("textBausteine");

      if (textBausteine.style.display === '') {
        textBausteine.style.display = 'block';
      } else {
        textBausteine.style.display = '';
      }ass
    },

    copyText(id){
       /* Get the text field */
      var copyText = document.getElementById(id);
      var finalText = document.getElementById('frist-name').value + ' ' + copyText.value;
      this.saveText(finalText);      
    },
    focusInput(){
      this.$refs.textfield.focus();
    },
  },
}
</script>

<style>

</style>