<template>
  <div class="form-input">
    <label for="frist-name" class="d-flex justify-content-center + col-sm-12">{{question}}</label>
    <label for="frist-name" class="d-flex justify-content-center + col-sm-12">{{question2}}</label>
    <textarea
      :class="inputClassObject('qualitaetsstandard_6')"
      type="text"
      id="frist-name"
      name="qualitaetsstandard_6"
      ref="textfield"
      :rules="qualitaetsstandard_6Rules"
      v-model.trim="qualitaetsstandard_6"
      autofocus
    />
    <ErrorMessage class="input-error-msg" name="qualitaetsstandard_6" />
  </div>

  <button
   @click="showText"
    type="button"
    class="btn-primary btn-back + textButton"

  > textbausteine </button>

    <div id="textBausteine" class="ContainerBaustein + col-sm-4">
        <div class="textitem"  @click="copyText('eins')">
            <h2> Textbaustein A</h2>
            <textarea id="eins" class="textblock"  value="ein kleiner und kurzer beschreibender Text" disabled/>             
        </div>
        <div class="textitem"  @click="copyText('2')">
            <h2> Textbaustein B</h2>
            <textarea id="2" value="ein beschreibender Text der etwas länger ist als der Text von Textbaustein A. Dieser sollte länger sein" class="textblock" disabled />
        </div>
        <div class="textitem" @click="copyText('3')">
            <h2> Textbaustein C</h2>
            <textarea id="3" value="ein wirklich langer Text, der über mehrere Zeilen gehen sollte, um einen ganzen Absatz darstellen zu können. Ab hier wiederholt sich dieser Text.
            ein wirklich langer Text, der über mehrere Zeilen gehen sollte, um einen ganzen Absatz darstellen zu können. Ab hier wiederholt sich dieser Text.
            ein wirklich langer Text, der über mehrere Zeilen gehen sollte, um einen ganzen Absatz darstellen zu können. Ab hier wiederholt sich dieser Text." class="textblock" disabled/>
        </div>
      </div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { Text } from 'vue'

export default {
  name: 'X_Qualitaetsstandard_6',
  components: {
    Field,
    ErrorMessage,
  },
  mounted() {
    this.focusInput();
  },
  props: {
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      qualitaetsstandard_6Rules: yup
        .string()
        .trim()
        .required(),    
      question: 'Bitte beschreiben Sie, wann und in welcher Weise Ihre Teilnehmer*innen für ihr entsprechendes Engagement Anerkennung erfahren (QS6)',
      question2: 'QS6: Das Engagement und die Leistungen der Schüler*innen werden durch Feedback im gesammten Prozess und bei einem anerkennenden Abschluss gewürdigt.',
    }
  },
  computed: {
    qualitaetsstandard_6: {
      get() {
        return this.$store.state.user.qualitaetsstandard_6
      },
      set(value) {
        this.$store.commit('updateQualitaetsstandard_6', value)
      },
    },    
  },
  methods: {
    inputClassObject(name) {
      return {
        'input-control': true,
        'has-error': this.errors.hasOwnProperty(name),
      }
    },

    saveText(text){

      this.qualitaetsstandard_6 = text;
    },

    showText(){
      var textBausteine = document.getElementById("textBausteine");

      if (textBausteine.style.display === '') {
        textBausteine.style.display = 'block';
      } else {
        textBausteine.style.display = '';
      }ass
    },

    copyText(id){
       /* Get the text field */
      var copyText = document.getElementById(id);
      var finalText = document.getElementById('frist-name').value + ' ' + copyText.value;
      this.saveText(finalText);      
    },
    focusInput(){
      this.$refs.textfield.focus();
    },
  },
}
</script>

<style>

</style>