<template>
  <div class="form-input">
    <label for="frist-name" class="d-flex justify-content-center">{{question}}</label>

    <div class="row" >
      <div class="col-sm-3"></div>
      <button type="button" id="buttonRow" class="btn-primary btn-next + col-sm-3"  @click="showText">Ja</button>
      <button type="button" id="buttonRow" class="btn-primary btn-next + col-sm-3" @click="hideText">Nein</button>
    </div>
    

    <textarea
      :class="inputClassObject('engagementPartner')"
      class="hiddenTextfield"
      type="text"
      id="frist-name"
      name="engagementPartner"
      ref="textfield"
      :rules="engagementPartnerRules"
      v-model.trim="engagementPartner"
      autofocus
    />
    <ErrorMessage class="input-error-msg" name="engagementPartner" />
  </div>

</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
  name: 'L_EngagementPartner',
  components: {
    Field,
    ErrorMessage,
  },
  mounted() {
    this.focusInput();
    this.checkText();
  },
  props: {
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      engagementPartnerRules: yup
        .string()
        .trim()
        .required(),    
      question: "Ist bereits ein(e) Engagement-Partner*in vorhanden?",
    }
  },
  computed: {
    engagementPartner: {
      get() {
        return this.$store.state.user.engagementPartner
      },
      set(value) {
        this.$store.commit('updateEngagementPartner', value)
      },
    },    
  },
  methods: {
    inputClassObject(name) {
      return {
        'input-control': true,
        'has-error': this.errors.hasOwnProperty(name),
      }
    },
    focusInput(){
      this.$refs.textfield.focus();
    },

     showText(){
      var textBausteine = document.getElementById("frist-name");      
        textBausteine.style.display = 'block';     
    },
    hideText(){
      var textBausteine = document.getElementById("frist-name");      
        textBausteine.style.display = 'none';     
        this.engagementPartner = '';
    },
    checkText(){
      var textBausteine = document.getElementById("frist-name");

      if(this.engagementPartner == ''){
        textBausteine.style.display = 'none';
      }
      else{
        textBausteine.style.display = 'block';
      }
    }
  },
}
</script>

<style>

</style>