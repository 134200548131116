<template>
  <Header />
  <main>
  
  <div class="row">
  <mainMenu class="col-sm-2"/>
    <router-view id="main" class="col-sm-10"/>
  </div>
  </main>
  <footer>
    <p></p>
  </footer>
</template>

<script>
import Header from './components/Header'
import mainMenu  from './components/mainMenu'
export default {
  components: {
    Header,
    mainMenu,
  },
}
</script>

<style>
/* Reset Global selectors */
html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  word-break: normal;
  -moz-tab-size: 4;
  tab-size: 4;
}

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

* {
  padding: 0;
  margin: 0;
}

button,
input {
  background-color: transparent;
  border-style: none;
}

/* Reset Global selectors */

main {
  min-height: 90vh;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
